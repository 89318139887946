import { SKILL_TYPES } from "@/base/data";

export default class SkillDTO {
  constructor() {
    this.id = null;
    this.description = null;
    this.skillName = null;
    this.skillType = SKILL_TYPES.TECHNICAL;
  }
}
