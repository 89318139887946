export const sensors = {
  NFC: "mdi-nfc",
  FACE: "mdi-face-recognition",
  RFID: "mdi-cast",
  FINGER: "mdi-fingerprint",
  BLUETOOTH: "mdi-bluetooth",
  GATEWARE: "tw-gateware",
  BEACON: "mdi-radar",
  GPS: "mdi-map-marker",
  WIFI: "mdi-wifi",
  ID: "mdi-account-outline",
  QR: "tw-qr-code",
  QR_CODE: "tw-qr-code",
  PASSWORD: "mdi-key",
  PIN: "mdi-dialpad",
  CARD: "mdi-credit-card",
  FIXED: "tw-hardware-terminals",
  EXTERNAL: "mdi-swap-horizontal",
  WEB: "mdi-application",
  JAN: "mdi-record-circle-outline"
};

export const identityTypeIcons = {
  FACE: "mdi-face-recognition",
  PASSWORD: "mid-key",
  PIN: "mdi-dialpad",
  FINGER: "mdi-fingerprint",
  QR_CODE: "tw-qr-code",
  CARD: "mdi-credit-card",
  EXTERNAL: "mdi-swap-horizontal"
};

export const eventTypes = {
  BREAK_IN: "tw-break-in",
  BREAK_OUT: "tw-break-out",
  LUNCH_IN: "tw-lunch-in",
  LUNCH_OUT: "tw-lunch-out",
  DUTY_IN: "tw-duty-in",
  DUTY_OUT: "tw-duty-out",
  CLOCK_IN: "tw-in",
  CLOCK_OUT: "tw-out",
  UNKNOWN: "mdi-arrow-left-right"
};

export const INTERVIEW_STATUS = {
  PLANNED: "PLANNED",
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
  CANCELLED: "CANCELLED"
};

export const SKILL_TYPES = {
  TECHNICAL: "TECHNICAL",
  SOFT: "SOFT",
  QUESTION: "QUESTION",
  LINK: "LINK"
};
export const EDUCATION_LANGUAGE = Object.freeze({
  TURKISH: "TURKISH",
  ENGLISH: "ENGLISH",
  GERMAN: "GERMAN",
  FRENCH: "FRENCH",
  DUTCH: "DUTCH",
  SPANISH: "SPANISH",
  ITALIAN: "ITALIAN",
  ARABIC: "ARABIC",
  RUSSIAN: "RUSSIAN",
  PERSIAN: "PERSIAN",
  CHINESE: "CHINESE",
  JAPANESE: "JAPANESE",
  HINDI: "HINDI",
  LATIN: "LATIN",
  PORTUGUESE: "PORTUGUESE",
  KOREAN: "KOREAN"
});
